* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  background-color: #000000;
}
section {
  height: 100vh;
  width: 100%;
  animation: fadeIn 0.5s linear;
}
div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
img {
  max-width: 100%;
  /* padding: 0 40px; */
}
.desktop {
  display: block;
  max-width: 780px;
  /* margin: 0 auto; */
}
.mobile {
  display: none;
}
@media (min-width : 1440px){
  .desktop {
    max-width: 54.1666vw;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}